<template>
  <div>
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
            src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"
            alt="Lightweight UI components for Vue.js based on Bulma"
          />
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item href="#"> Home </b-navbar-item>
        <b-navbar-item href="#"> Documentation </b-navbar-item>
        <b-navbar-dropdown label="Info">
          <b-navbar-item href="#"> About </b-navbar-item>
          <b-navbar-item href="#"> Contact </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
    <section style="padding: 50px">
      <div class="card" aria-id="contentIdForA11y3" style="max-width: 1160px">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
        >
          <p class="card-header-title">
            <template v-if="!loading">Title</template>
            <b-skeleton size="is-large" :active="loading"></b-skeleton>
          </p>
        </div>
        <div class="card-content" style="padding: 0px">
          <template v-if="!loading">
            <!-- {{ JSON.stringify(this.article) }} -->
            <mavon-editor
              :subfield="false"
              :defaultOpen="'preview'"
              :toolbarsFlag="false"
              :editable="false"
              :scrollStyle="true"
              :ishljs="true"
              v-model="article.raw.text"
              style="
                z-index: 0;
                min-height: 10px;
                max-height: 1500px;
                padding: 0px;
              "
            />
          </template>
          <b-skeleton size="is-large" :active="loading" :count="2"></b-skeleton>
        </div>
        <footer class="card-footer" v-if="current == 1">
          <a class="card-footer-item">
            <b-tooltip label="好活" type="is-light" position="is-top">
              <template v-if="!loading">👍</template>
            </b-tooltip>
            <b-skeleton size="is-large" :active="loading"></b-skeleton>
          </a>
          <a class="card-footer-item">
            <b-tooltip label="出警" type="is-light" position="is-top">
              <template v-if="!loading">🚔</template>
            </b-tooltip>
            <b-skeleton size="is-large" :active="loading"></b-skeleton>
          </a>
          <a class="card-footer-item">
            <b-tooltip label="回复" type="is-light" position="is-top">
              <template v-if="!loading">对线</template>
            </b-tooltip>
            <b-skeleton size="is-large" :active="loading"></b-skeleton>
          </a>
        </footer>
      </div>
      <br />

      <div
        v-for="reply in article.replys"
        :key="reply"
        class="text item"
        align="center"
      >
        <div class="card" style="max-width: 900px">
          <div class="card-header">
            <p class="card-header-title">
              {{ reply.title }}
            </p>
          </div>
          <mavon-editor
            :subfield="false"
            :defaultOpen="'preview'"
            :toolbarsFlag="false"
            :editable="false"
            :scrollStyle="true"
            :ishljs="true"
            v-model="reply.text"
            :boxShadow="false"
            style="z-index: 0; min-height: 10px; max-height: 200px"
          />
          <footer class="card-footer" style="max-height: 40px">
            <a class="card-footer-item">
              <b-tooltip label="好活" type="is-light" position="is-top">
                <template v-if="!loading">👍</template>
              </b-tooltip>
              <b-skeleton size="is-large" :active="loading"></b-skeleton>
            </a>
            <a class="card-footer-item">
              <b-tooltip label="出警" type="is-light" position="is-top">
                <template v-if="!loading">🚔</template>
              </b-tooltip>
              <b-skeleton size="is-large" :active="loading"></b-skeleton>
            </a>
            <a class="card-footer-item">
              <b-tooltip label="回复" type="is-light" position="is-top">
                <template v-if="!loading">对线</template>
              </b-tooltip>
              <b-skeleton size="is-large" :active="loading"></b-skeleton>
            </a>
          </footer>
        </div>
        <br />
      </div>
    </section>
    <section
      style="
        padding-top: 0px;
        padding-bottom: 1cm;
        padding-left: 50px;
        padding-right: 50px;
      "
    >
      <b-pagination
        :total="total"
        v-model="current"
        rounded="true"
        :per-page="perPage"
        icon-prev="chevron-left"
        icon-next="chevron-right"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        @change="nextpage"
      >
      </b-pagination>
    </section>
    <section style="top: 50px">
      <b-sidebar
        type="is-white"
        right="true"
        expand-on-hover="true"
        reduce="true"
        v-model="open"
        style=""
      >
        <div class="p-1">
          <b-menu>
            <b-menu-list label="Menu">
              <b-menu-item
                label="主页"
                tag="router-link"
                :to="{ path: '/forum' }"
              ></b-menu-item>
              <b-menu-item label="账户">
                <b-menu-item label="个人信息"></b-menu-item>
                <b-menu-item label="登出"></b-menu-item>
              </b-menu-item>
            </b-menu-list>
            <b-menu-list label="Actions">
              <b-menu-item
                label="发帖"
                @click="writeReply = true"
              ></b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
      </b-sidebar>
    </section>
    <section>
      <b-modal v-model="writeReply" :width="640" scroll="keep">
        <b-field label="Title" label-position="inside">
          <b-input value="" v-model="title"></b-input>
        </b-field>
        <mavon-editor
          ref="md"
          v-model="text"
          @imgAdd="$imgAdd"
          :boxShadow="false"
          style="z-index: 0; min-height: 500px"
          placeholder="请输入正文"
        />
        <div class="buttons">
          <b-button type="is-primary" @click="goReply" expanded>回复</b-button>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css"; // 未导入该样式会出现工具栏无法正常显示的问题

export default {
  components: {
    mavonEditor,
  },
  data() {
    return {
      fullheight: true,
      open: true,
      pid: this.$route.query.pid,
      article: null,
      art_reply: null,
      //reply in first doc
      loading: true,
      total: 0,
      current: 1,
      perPage: 10,
      rangeBefore: 3,
      rangeAfter: 1,
      writeReply: false,
      text: "",
      title: "",
      media: [],
    };
  },
  methods: {
    async $imgAdd(pos, $file) {
      let _bucket = "posts";
      let res = await this.$http.post(
        "/api/oss/presign/add",
        { bucket: _bucket },
        {
          headers: {
            pass: "",
            Authorization: "Bearer " + (await localStorage.getItem("token")),
          },
        }
      );
      console.log(res.data);
      let url;
      if (res.data.status == 20000 && res.data.url != null) {
        let status = await this.$http({
          url: res.data.url,
          data: $file,
          method: "PUT",
        });
        console.log(status);
        // url = res.data.url.split("?")[0];
        url = res.data.exp_url;

        console.log(url);
      } else {
      }
      if (url != null) {
        this.media.push({ url: url, name: $file._name });
        await this.$refs.md.$img2Url(pos, url);
      }
    },
    async goReply() {
      if (this.text == "" || this.title == "") {
        return this.$buefy.toast.open("什么都没写就想发帖");
      }
      let res = await this.$http.post(
        "/api/posts/reply/" + this.$route.query.pid,
        {
          text: this.text,
          title: this.title,
          media: this.media,
        },
        {
          headers: {
            pass: "",
            Authorization: "Bearer " + (await localStorage.getItem("token")),
          },
        }
      );
      console.log(res.data);
      if (res.data.status == 20000) {
        this.writeReply = false;
        this.$buefy.toast.open({
          message: "成功发布回复",
          type: "is-success",
        });
      }
    },
    async nextpage() {
      let res = await this.$http.get(
        "/api/posts/view/" +
          this.$route.query.pid +
          "/" +
          this.current.toString(),
        {
          headers: {
            pass: "",
            Authorization: "Bearer " + (await localStorage.getItem("token")),
          },
        }
      );
      if (this.current == 1) {
        this.article.replys = this.art_reply.concat(res.data.reply.replys);
      } else {
        //problem here
        this.article = { raw: this.article.raw, replys: res.data.reply.replys };
      }
      this.article.raw.text = this.article.raw.text.substr(0, 300);
      console.log(this.article);
      this.hangleGoUp();
    },
    hangleGoUp() {
      let nowTop =
        document.body.scrollTop || document.documentElement.scrollTop; // 获取当前滚动条位置
      if (nowTop > 0) {
        window.requestAnimationFrame(this.hangleGoUp);
        window.scrollTo(0, nowTop - nowTop / 50);
      }
    },
  },

  async beforeCreate() {
    //页号问题，少了一页
    this.pid = this.$route.query.pid;
    let res = await this.$http.get("/api/posts/view/" + this.pid, {
      headers: {
        pass: "",
        Authorization: "Bearer " + (await localStorage.getItem("token")),
      },
    });
    this.article = res.data;
    this.loading = false;
    res = await this.$http.get("/api/posts/preview/" + this.$route.query.pid, {
      headers: {
        pass: "",
        Authorization: "Bearer " + (await localStorage.getItem("token")),
      },
    });
    this.total = +res.data.reply;
    res = await this.$http.get(
      "/api/posts/view/" + this.$route.query.pid + "/1",
      {
        headers: {
          pass: "",
          Authorization: "Bearer " + (await localStorage.getItem("token")),
        },
      }
    );
    this.art_reply = this.article.replys;
    this.article.replys = this.article.replys.concat(res.data.reply.replys);
  },
};
</script>

<style>
</style>